.Construction-page {
  position: relative;
  background-color: transparent;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
}

.Construction-page .logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
  flex-direction: column;
}
.Construction-page .logos {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
}

.Construction-page .background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  overflow: hidden;
}

.Construction-page .background-image .bg-img {
  width: 100%;
  height: 100%;
  overflow: hidden;
  object-fit: cover;
  filter: brightness(20%) blur(1px);
}

.Construction-page .logo-container .App-logo {
  height: 10vmin;
  pointer-events: none;
}

.Construction-page .logo-container .App-logo-text {
  height: 10vmin;
}

.cons-error-text {
  font-size: 2.5rem;
  font-weight: 400;
  background: linear-gradient(90deg, #cbcbcb 0%, #6b6b6b 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: "Squada One", sans-serif;
  letter-spacing: 2px;
  margin-top: 0rem;
  text-transform: capitalize;
}

.cons-redirect-button {
  padding: 0.75rem 2rem;
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: 500;
  border: none;
  border-radius: 8px;
  background-color: var(--primary-color-red);
  transition: transform 0.3s ease-in-out;
}

.cons-redirect-button:hover {
  transform: scale(1.02);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
