@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Squada+One&display=swap");
.html {
  font-size: 16px;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  --primary-color-red: #ad0000;
  font-family: "Roboto", system-ui;
  color: #fff;
}

.container {
  max-width: 1440px;
  margin: 0 auto;
}
